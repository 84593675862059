import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import projImg6 from "../assets/img/project-img6.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Streaming Platform",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Service Company",
      description: "Marketing & Development",
      imgUrl: projImg2,
    },
    {
      title: "Service Company",
      description: "Marketing & Development",
      imgUrl: projImg3,
    },
    {
      title: "informations enterprises",
      description: "Marketing & Development",
      imgUrl: projImg4,
    },
    {
      title: "Business Startup",
      description: "Marketing & Leading",
      imgUrl: projImg5,
    },
    {
      title: "E-commerce website",
      description: "Design & Development",
      imgUrl: projImg6,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Each project I’ve worked on reflects my commitment to innovation, technical excellence, and delivering results. Below are some key projects that showcase my expertise in web development and digital marketing, driving both user engagement and business growth.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>While at Manageo, I led key web development and digital marketing projects for high-profile clients such as Toyota, Amazon France, Edenred, and KIA. I developed responsive landing pages and email templates using MJML and React.js, ensuring consistent performance across devices. Additionally, I implemented automated lead export systems using Node.js and cron jobs, streamlining client workflows. By collaborating closely with design and marketing teams, I successfully optimized user experiences and helped clients achieve significant increases in engagement and conversion rates.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}